// @ts-strict-ignore
import React, { useState } from "react"

import { Auth } from "@aws-amplify/auth/lib/Auth"

import Alert from "@kiwicom/orbit-components/lib/Alert"
import Button from "@kiwicom/orbit-components/lib/Button"
import Heading from "@kiwicom/orbit-components/lib/Heading"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"

type RefreshPermissionsType = {
  type: "error" | "other"
}

const RefreshPermissions = ({ type }: RefreshPermissionsType) => {
  const [refreshingToken, setRefreshingToken] = useState(null)
  const [refreshedTokenOutcome, setRefreshedTokenOutcome] = useState(null)

  function refreshAccessToken() {
    setRefreshingToken(true)
    setRefreshedTokenOutcome(null)
    Auth.currentAuthenticatedUser().then((user) => {
      const currentSession = user.signInUserSession
      user.refreshSession(currentSession.refreshToken, (error, _session) => {
        if (error) {
          setRefreshedTokenOutcome({ error: error })
          setRefreshingToken(false)
        } else {
          setRefreshedTokenOutcome({ success: true })
          setRefreshingToken(false)
        }
      })
    })
  }

  return (
    <Container size="narrow">
      <Stack direction="column" spacing="large" align="center">
        {type == "error" && (
          <>
            <Heading>Permissions Error</Heading>
            <Text align="center">
              You don't have permissions to visit this page. If your permissions
              have recently changed, try clicking the update button below.
            </Text>
          </>
        )}
        {refreshedTokenOutcome == null && (
          <Button
            disabled={refreshingToken}
            loading={refreshingToken}
            onClick={() => refreshAccessToken()}
          >
            Update my permissions
          </Button>
        )}
        {refreshedTokenOutcome?.success && (
          <Alert title="Refreshed permissions successfully">
            Try reloading the page to see if this fixes the issue
          </Alert>
        )}
        {refreshedTokenOutcome?.error && (
          <Alert title="Failed to refresh token">
            {JSON.stringify(refreshedTokenOutcome.error)}
          </Alert>
        )}
      </Stack>
    </Container>
  )
}

export default RefreshPermissions
