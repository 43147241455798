import React from "react"

import Heading from "@kiwicom/orbit-components/lib/Heading"
import Stack from "@kiwicom/orbit-components/lib/Stack"
import Text from "@kiwicom/orbit-components/lib/Text"

import Container from "components/container"
import Layout from "components/layout"
import RefreshPermissions from "components/refresh-permissions"

const RefreshPermissionsPage = () => (
  <Layout title="Refresh Permissions">
    <Container size="narrow">
      <Stack direction="column" spacing="large" align="center">
        <Heading>Refresh Your Permissions</Heading>
        <Text align="center">
          Try refreshing your permissions by clicking the button below.
        </Text>
        <RefreshPermissions type="other" />
      </Stack>
    </Container>
  </Layout>
)

export default RefreshPermissionsPage
